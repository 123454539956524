import React, { useState } from 'react';
import Layout from "../components/layout"
import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"

export default function Supplier() {
    const [headerColor,setHeaderColor] = useState("bg-white text-black")
    const [typeVal,setTypeVal] = useState('factory')

    const [submitLoading,setSubmitLoading] = useState(false)
    const submitFun = (e)=>{
        e.preventDefault()
        const company = e.target.querySelector("[name='company']").value || ''
        const product = e.target.querySelector("[name='product']").value || ''
        const contact = e.target.querySelector("[name='contact']").value || ''
        const type = typeVal
        const phone = e.target.querySelector("[name='phone']").value || ''
        const record = e.target.querySelector("[name='record']").value || ''
        const advantage = e.target.querySelector("[name='advantage']").value || ''

        setSubmitLoading(true)
        fetch('https://admin.cnsotto.com/supplier-forms',{
            method:'POST',
            headers:{
                'Content-Type':'application/json;charset=UTF-8'
            },
            mode:'cors',
            cache:'default',
            body: JSON.stringify({
                company,product,contact, type, record, phone, advantage
            })
        })
        .then(res =>res.json())
        .then((data) => {
            setSubmitLoading(false)
            if(data.id){
                Toastify({
                    text: `你的表单已经提交成功!`,
                    position:"center"
                }).showToast();
            }
        })
    }

    return (
        <Layout bgColor={'bg-white'} footer={true} headerColor={headerColor}>
            <div className="container mx-auto pt-[193px] sm:pt-24">
                <div className="flex justify-between items-end pb-[100px] sm:flex-col sm:items-start sm:pb-14">
                    <h1 className="text-[40px] leading-snug sm:mb-8">取得联系</h1>
                    <ul className="flex items-center text-gray flex-wrap sm:w-full sm:justify-center">
                        <li className="mr-[38px] sm:mr-5"><a className="hover:text-black" href="/contact">联系方式</a></li>
                        <li className="mr-[38px] sm:mr-5"><a className="hover:text-black" href="/job">工作机会</a></li>
                        <li className="mr-[38px] sm:mr-5"><a className="text-black" href="/supplier">供应商体系</a></li>
                        <li><a href="/sale-service" className="hover:text-black">售后服务</a></li>
                    </ul>
                </div>
            </div>
              
            <div className="container mx-auto pb-[140px]">    
                <div className="flex justify-between xl:flex-col">
                    <h2 className="text-3xl leading-snug items-start xl:pb-8">
                    供应商体系
                    </h2>
                    <form onSubmit={submitFun} className="grid grid-cols-2 gap-x-[50px] gap-y-10 w-[940px] xl:w-full sm:gap-x-8">
                        <div>
                            <input type="text" name="company" placeholder="企业名称" required/>
                        </div>
                        <div>
                            <input type="text" name="product" placeholder="供应商产品/类型" required/>
                        </div>
                        <div>
                            <input type="text" name="contact" placeholder="联系人" required/>
                        </div>
                        <div>
                            <input type="text" name="phone" placeholder="联系方式" required/>
                        </div>
                        <div className="flex items-center justify-between xl:flex-col xl:items-start">
                            <div className="mr-3 xl:mb-3">企业类型 </div>
                            <div className="flex items-center flex-wrap">
                                <label className="flex items-center mr-4"><input onChange={(e)=>{setTypeVal(e.target.value)}} checked name="type" value="factory" type="radio" className="mr-1"/>工厂</label>
                                <label className="flex items-center mr-4"><input onChange={(e)=>{setTypeVal(e.target.value)}} name="type" value="trader" type="radio" className="mr-1"/>贸易商</label>
                                <label className="flex items-center mr-4"><input onChange={(e)=>{setTypeVal(e.target.value)}} name="type" value="agent" type="radio" className="mr-1"/>代理商</label>
                                <label className="flex items-center"><input onChange={(e)=>{setTypeVal(e.target.value)}} name="type" value="company" type="radio" className="mr-1"/>服务公司</label>
                            </div>
                        </div>
                        <div>
                            <input type="text" name="record" placeholder="供货质量记录" required/>
                        </div>
                        <div className="col-span-2">
                            <textarea name="advantage" placeholder="您的优势" cols="30" rows="5"></textarea>
                        </div>
                        <div className="col-span-2">
                            <input type="submit" disabled={submitLoading} className="disabled:cursor-not-allowed disabled:opacity-50 w-[140px] h-10 flex justify-center items-center rounded-full border-[2px] border-black text-black bg-transparent hover:bg-black hover:text-white" value="提交"/>
                        </div>
                    </form>
                </div>
            </div>
        </Layout>
    )
}

